/* CustomDatePicker.css */
.react-datepicker__day--selected {
  @apply bg-primary text-white hover:bg-primary;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-primary text-white hover:bg-primary;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-primary text-white hover:bg-primary;
}

.react-datepicker__day--in-range {
  @apply bg-primary text-white hover:bg-primary;
}

.react-datepicker__day--range-start {
  @apply bg-primary text-white hover:bg-primary;
}

.react-datepicker__day--range-end {
  @apply bg-primary text-white hover:bg-primary;
}
