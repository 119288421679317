@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SharpSans-Bold';
  src: url('./assets/fonts/SharpSans-Bold.otf');
}
@font-face {
  font-family: 'SharpSans-Semibold';
  src: url('./assets/fonts/SharpSans-Semibold.otf');
}
@font-face {
  font-family: 'SharpSans-Regular';
  src: url('./assets/fonts/SharpSans.otf');
}

@font-face {
  font-family: 'WorkSans-Regular';
  src: url('./assets/fonts/WorkSans/static/WorkSans-Regular.ttf');
}

@font-face {
  font-family: 'WorkSans-Medium';
  src: url('./assets/fonts/WorkSans/static/WorkSans-Medium.ttf');
}
@font-face {
  font-family: 'WorkSans-Bold';
  src: url('./assets/fonts/WorkSans/static/WorkSans-Bold.ttf');
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url('./assets/fonts/WorkSans/static/WorkSans-SemiBold.ttf');
}

:root {
  --tw-color-neon: 227 255 00;
  --color-neon: rgb(var(--tw-color-neon));
  --tw-color-neon_dark: 167 169 00;
  --color-neon_dark: rgb(var(--tw-color-neon_dark));
  --tw-color-neon_light: 220 247 00;
  --color-neon_light: rgb(var(--tw-color-neon_light));
  --tw-color-navy: 19 59 161;
  --color-navy: rgb(var(--tw-color-navy));
  --tw-color-navy_light: 194 208 242;
  --color-navy_light: rgb(var(--tw-color-navy_light));
  --tw-color-navy_light_semi: 119 151 230;
  --color-navy_light_semi: rgb(var(--tw-color-navy_light_semi));
  --tw-color-burgundy: 132 52 78;
  --color-burgundy: rgb(var(--tw-color-burgundy));
  --tw-color-burgundy_light: 234 203 178;
  --color-burgundy_light: rgb(var(--tw-color-burgundy_light));
  --tw-color-burgundy_light_semi: 230 133 133;
  --color-burgundy_light_semi: rgb(var(--tw-color-burgundy_light_semi));
  --tw-color-success: 84 165 10;
  --color-success: rgb(var(--tw-color-success));
  --tw-color-danger: 180 52 78;
  --color-danger: rgb(var(--tw-color-danger));
  --tw-color-default_cool_gray: 147 149 152;
  --color-default_cool_gray: rgb(var(--tw-color-default_cool_gray));
  --tw-color-default_gray: 233 233 234;
  --color--default_gray: rgb(var(--tw-color-default_gray));
  --color-default_warm_gray: #d1d1d3;
  --color-default_dark_gray: #b2b2b2;
  --color-disabled_color: rgba(0, 0, 0, 0.3);
  --color-light-gray: rgba(250, 250, 250, 1);
  --color-light-blue: rgba(0, 130, 251, 1);
  --color-between-gray: rgb(209, 209, 211, 100);
}

/* #region  /**=========== Typography =========== */
/* .h0 {
  @apply text-3xl font-bold md:text-5xl;
}

h1,
.h1 {
  @apply text-2xl font-bold md:text-4xl;
}

h2,
.h2 {
  @apply text-xl font-bold md:text-3xl;
}

h3,


h4,
.h4 {
  @apply text-base font-bold md:text-lg;
} */

.fadeaway {
  position: relative;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* @media (min-width: 576px) {
  h1 {
    @apply text-15px;
  }
  p {
    line-height: 24px;
  }
} */

h1 {
  @apply text-21px leading-29px font-worksans_bold;
}
p,
ul {
  @apply text-14px leading-24px font-worksans_medium;
}

@media (min-width: 992px) {
  h1 {
    @apply text-21px leading-29px;
  }
  p,
  ul {
    @apply text-14px leading-24px;
  }
}

@media (min-width: 1200px) {
  h1 {
    @apply text-32px leading-38px;
  }
  p,
  ul {
    @apply text-16px leading-24px;
  }
}

@media (min-width: 1680px) {
  h1 {
    @apply text-40px leading-50px;
  }
  h3 {
    @apply text-25px leading-50px;
  }
  p,
  ul {
    @apply text-21px leading-24px;
  }
}

.fadeaway::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'WorkSans-Regular', sans-serif;
}
.p {
  @apply text-sm md:text-base;
}

ul {
  list-style-type: inherit;
}

ul > li {
  margin-left: 1.7em;
}
/* #endregion  /**======== Typography =========== */

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d3;
  border-radius: 6px;
}

/* Animation */
.fading-animation {
  animation-name: fade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.8s;
  animation-direction: alternate;
}
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toolTipCardContainer .tooltipCard {
  color: black;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* Table css */
table {
  width: 100%;
}

table thead th {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 -1px 0 #ebf0ed;
  border-radius: 25px 25px 0 0;
}

table thead th {
  background-clip: padding-box;
  z-index: 0;
}

table thead {
  z-index: 1 !important;
}
