.swiper {
  width: 100%;
  height: 167px;
  border-radius: 10px;
}

.swiper-slide {
  text-align: center;
  width: 213px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.swiper-button-prev {
  background-image: url(../../assets/svg/carousel-arrow-right.svg) !important;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  background-position: center;
  top: var(--swiper-navigation-top-offset, 0%);
  width: 36px;
  margin-top: 0;
  background-color: var(--color-between-gray);
  height: 100%;
  border-radius: 10px;
  left: 0;
}

.swiper-button-next {
  background-image: url(../../assets/svg/carousel-arrow-right.svg) !important;
  background-image-width: 2px;
  background-repeat: no-repeat;
  background-position: center;
  top: var(--swiper-navigation-top-offset, 0%);
  width: 36px;
  margin-top: 0;
  background-color: var(--color-between-gray);
  height: 100%;
  border-radius: 10px;
  right: 0;
  /* margin-right: 30px; */
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}
